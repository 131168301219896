html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(252, 237, 242, 0.582),
    rgba(252, 237, 242, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(252, 237, 242, 0.678),
    rgba(252, 237, 242, 0.863)
  );

  --imp-text-color: #c095e4; /* Neuer Textfarbe */
}
.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #fcedf2;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fcedf2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Landingpage Section  */
/* --------- */

.landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fcedf2;
  color: #ff8fab;
}

.landing-page-title {
  font-size: 52px;
  color: #fb6f92;
  text-shadow: 1px 1px 0 #fb6f92, -1px -1px 0 #fb6f92, 1px -1px 0 #fb6f92;
  margin-bottom: 20px;
  text-align: center;
}

.landing-page-age {
  margin-bottom: 20px;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.landing-page-button {
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  height: 50px;
  width: 90px;
  font-weight: bold;
  background-color: transparent;
  color: #ff8fab;
  border: 2px solid #ff8fab;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.landing-page-button:hover {
  background-color: rgba(255, 229, 236, 1);
}
/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: white !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-title {
  font-size: 2rem;
  margin: 0;
  color: #fb6f92;
  text-shadow: 1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #fb6f92 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #ffc2d1 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: #fb6f92 !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #c095e4;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */

#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 10px !important;
}

.home-content {
  padding: 7rem 0 2rem !important;
  color: #ff8fab;
  text-align: left;
}

.no-underline {
  text-decoration: none;
}

.heading {
  font-size: 2em !important;
  padding-left: 50px !important;
}

.subheading {
  font-size: 1.6em !important;
  padding-left: 50px !important;
}

.purple-text {
  color: #fb6f92;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.home-about-section {
  position: relative;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.home-about-description {
  color: #c095e4 !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.icon-colour {
  color: #700c86 !important;
}

.icon-colour-of {
  color: #00aff0 !important;
  height: 60px;
  width: 60px;
}

.text-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px; /* Abstand zu beiden Seiten */
  padding-right: 20px; /* Abstand zu beiden Seiten */
  max-width: 800px; /* Maximale Breite des Containers */
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: justify;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

/* Social Icons */
.home-about-social-links {
  display: flex;
  justify-content: center;
  gap: 20px; /* Abstand zwischen den Icons */
}

.social-icons {
  display: inline-block;
}

.home-social-icons {
  width: 50px; /* Einheitliche Breite */
  height: 50px; /* Einheitliche Höhe */
}

.icon-image {
  display: block;
  width: 50px; /* Einheitliche Breite */
  height: 50px; /* Einheitliche Höhe */
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  position: fixed;
  background-color: #ffc2d1;
  bottom: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;
  /* padding-top: 10px !important;
  padding-bottom: 8px !important ; */
}
.footer-copywright {
  padding-top: 10px !important;
  text-align: center !important;
  color: #fb6f92 !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer-icons {
  margin-top: 0em !important;
  margin-bottom: 1em !important;
  padding: 0 !important;
  color: #ff8fab !important;
}

/* --------- */
/* Sites */
/* --------- */

.Site-heading {
  color: #fb6f92 !important;
}
.Site-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.Site-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.Site-card-view {
  box-shadow: 0 4px 5px 3px rgba(255, 179, 198, 0.459) !important; /* Neue Farbe */
  color: #fb6f92 !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.Site-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 5px 3px rgba(251, 111, 146, 0.459) !important;
}

.site-card-img {
  padding: 15px;
  box-sizing: border-box;
}
