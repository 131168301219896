.custom-button {
  background-color: #ff8fab; /* Hintergrundfarbe des Buttons */
  color: #ffffff; /* Schriftfarbe */
  border: 2px solid #ffffff; /* Weißer Rahmen */
  padding: 10px 20px; /* Innenabstand */
  font-size: 16px; /* Schriftgröße */
  border-radius: 0.5em; /* Abgerundete Ecken */
  cursor: pointer; /* Zeiger ändern */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Übergangseffekt */
  position: relative; /* Für das Glow-Element */
  overflow: hidden; /* Verhindert, dass das Glow-Element über den Button hinausgeht */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Absenken der Ränder */
  animation: backgroundAnimation 3s infinite alternate; /* Animation hinzufügen */
}

.custom-button::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid #ff8fab; /* Rahmen in der Hintergrundfarbe */
  border-radius: 0.5em; /* Gleiche abgerundete Ecken wie der Button */
  pointer-events: none; /* Verhindert, dass der zusätzliche Rahmen anklickbar ist */
}

.custom-button .glow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background-color: rgba(255, 255, 255, 0.5); /* Weißes Glow */
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Verhindert, dass das Glow-Element anklickbar ist */
}

.custom-button:hover {
  background-color: #ff6f91; /* Dunklere Hintergrundfarbe beim Hover */
}

.custom-button:focus {
  outline: none; /* Entfernt den Standard-Fokusrahmen */
}

.custom-button:active {
  transform: scale(0.98); /* Leichtes Schrumpfen beim Klicken */
}

@keyframes backgroundAnimation {
  0% {
    background-color: #ff8fab;
  }
  100% {
    background-color: #ff6f91;
  }
}
